<template>
  <div class="app-container">
    <div style="width: 1200px;">
      <img
        v-if="this.$language === 'cn'"
        style="width: 100%; height: auto;"
        src="../../assets/images/aboutus/about_us_cn.png"
      >
      <img
        v-else
        style="width: 100%; height: auto;"
        src="../../assets/images/aboutus/about_us_en.png"
      >
    </div>
    <div class="our_prent">
      <span>{{ $t('m.navbar.about_us') }}</span>
    </div>
    <div class="aboutUs_img">
      <!-- 16+1框架 -->
      <div class="text_box">{{ $t('m.aboutus.frame_text') }}</div>
      <div class="line_gary" />
      <div class="line" />
    </div>
    <!-- 16+1框架 -->
    <div class="aboutUs_content">
      <!-- 文字介绍 -->
      <div v-if="this.$language === 'cn'" class="aaboutUs_left">
        <p class="contr_16 line_35">
          &emsp;&emsp;2016年11月，首届中国－中东欧国家创新合作大会在南京成功举办，会上发布了《中国－中东欧国家创新合作南京宣言》，并为“中国－中东欧国家（虚拟）技术转移中心”举行了揭牌仪式。<br>
        </p>
        <p class="contr_16 line_35">
          &emsp;&emsp;受科技部委托，江苏省对外科学技术交流中心负责建设中国－中东欧国家技术转移虚拟中心在中国的门户站点，并于 2017年11月第二届中国－中东欧国家创新合作大会上，正式上线中国－中东欧国家（虚拟）技术转移中心网站。
        </p>
        <p class="contr_16 line_35">
          &emsp;&emsp;2021年9月，科技部王志刚部长在第五届中国－中东欧国家创新合作大会上指出，在中国－中东欧国家虚拟技术转移中心的基础上，要积极推动建设中国－中东欧国家技术转移中心，打造良好技术转移转化生态系统。
        </p>
      </div>
      <div v-else class="aaboutUs_left" style="padding-top: 0px;">
        <p class="contr_16 line_en">
          The first China-Central and Eastern European Countries (CEEC) Conference on Innovation Cooperation was held in Nanjing in November, 2016, during which Nanjing Declaration on China-CEEC Innovation Cooperation was issued, and the Virtual —“16 + 1 Cooperation” Technology Transfer Centre (VTTC) was unveiled.
        </p>
        <p class="contr_16 line_en">
          Commissioned by Ministry of Science and Technology of the People’s Republic of China (MOST), Jiangsu S&T Exchange Center with Foreign Countries took the charge of setting up the Chinese web portal for VTTC, which was officially launched during the second China-CEEC Conference on Innovation Cooperation in November, 2017.
          <!--Commissioned by Ministry of Science and Technology of the People’s Republic of China, Jiangsu S&T Exchange Center with Foreign Countries took the charge of setting up the Chinese web portal for VTTC, which was officially launched during the second China-CEEC Conference on Innovation Cooperation in November, 2017.-->
        </p>
        <p class="contr_16 line_en">
          In September, 2021, during the 5<sup>th</sup> China-CEEC Conference on Innovation Cooperation, Wang Zhigang, Minister for MOST, pointed out that we would build upon the success of the VTTC to promote the establishment of China-CEEC Technology Transfer Centre, creating a favorable ecosystem for technology transfer.
          <!--In September, 2021, during the 5<sup>th</sup> China-CEEC Conference on Innovation Cooperation, Wang Zhigang, Minister for Science and Technology, pointed out that we would build upon the success of the VTTC to promote the establishment of China-CEEC Technology Transfer Centre, creating a favorable ecosystem for technology transfer.-->
        </p>
      </div>
      <!-- 16国图片 -->
      <!--<div class="aaboutUs_right">-->
        <!--<div style="width: 618px;">-->
          <!--<country-box style="transform: scale(0.88, 0.88); transform-origin: left top;" />-->
        <!--</div>-->
      <!--</div>-->
    </div>
    <div style="clear: both;"></div>
    <!-- -->
    <div class="aboutUs_img" style="margin-top: 80px !important;">
      <!-- 虚拟"16+1合作"技术转移中心 -->
      <div class="text_box">{{ $t('m.aboutus.manifesto_text') }}</div>
      <div class="line_gary" />
      <div class="line" />
    </div>
    <div class="jszyzx">
      <span v-if="this.$language === 'cn'" class="jszyzx_a">
        &emsp;&emsp;中心建设本着“共商、共建、共享”的原则，整合中国－中东欧国家创新资源，构建中国－中东欧国家科技创新与技术转移协作网络，推动双向转移合作与打造国际创新共同体，搭建线上线下综合服务平台，广泛建立创新合作朋友圈，推动中国与中东欧国家科技创新合作迈上新台阶。
      </span>
      <span v-else class="jszyzx_a" style="line-height: 30px;">
         Acting on the principle of extensive consultation, joint contribution and shared benefits, the centre aims to integrate innovation resources in China and CEECs to build a network for coordinated innovation and technology transfer that serves two-way technology transfer collaboration and helps build a global innovation community. Both virtual and physical platforms for comprehensive service will be established and innovation partnership will be further extended, so as to further promote innovative and technological cooperation between China and CEECs.
        <!--Acting on the principle of extensive consultation, joint contribution and shared benefits, the Centre aims to integrate innovation resources in China and CEEC to build a network for coordinated innovation and technology transfer that serves two-way technology transfer collaboration and helps build a global innovation community. Both virtual and physical platforms for comprehensive service will be established and innovation partnership will be further extended, so as to further promote innovative and technological cooperation between China and CEE countries.-->
      </span>
      <span v-if="this.$language === 'cn'" class="jszyzx_b">
        &emsp;&emsp;{{ $t('m.aboutus.service_text') }}
      </span>
      <span v-else class="jszyzx_b">
        {{ $t('m.aboutus.service_text') }}
      </span>
      <!-- style="list-style-type: none;" -->
      <ol v-if="this.$language === 'cn'" style="list-style-type: none; padding: 0;">
        <li>&emsp;&emsp;1. 提供有对接价值的中国和中东欧国家的产业创新合作需求；</li>
        <li>&emsp;&emsp;2. 提供中国与中东欧国家技术转移高效对接渠道；</li>
        <li>&emsp;&emsp;3. 提供专业化的跨国技术转移中介服务；</li>
        <li>&emsp;&emsp;4. 打造中国与中东欧国家产业创新服务网络和载体平台；</li>
        <li>&emsp;&emsp;5. 组织综合性与专题性的产业创新合作对接活动。</li>
      </ol>
      <ol v-else style="list-style-type: none; padding: 0;">
        <li>1. Releasing demand for industrial innovation cooperation in China and CEECs.</li>
        <li>2. Offering channels for efficient technology transfer and matchmaking between China and CEECs.</li>
        <li>3. Delivering professional intermediary service for international technology transfer.</li>
        <li>4. Building service network and platform for industrial innovation in China and CEECs.</li>
        <li>5. Organizing comprehensive or thematic events for industrial innovation cooperation.</li>
      </ol>
    </div>
    <!--<div class="aboutUs_img" style="margin-top: 80px !important;">-->
      <!--&lt;!&ndash;<img src="../../assets/images/aboutus/our_title_08.png">&ndash;&gt;-->
      <!--&lt;!&ndash; 宣言 &ndash;&gt;-->
      <!--<div class="text_box">{{ $t('m.aboutus.manifesto_text') }}</div>-->
      <!--<div class="line_gary" />-->
      <!--<div class="line" />-->
    <!--</div>-->
    <!--<div class="jszyzx_sm">-->
      <!--<span>-->
        <!--此《宣言》由“16+1合作”伙伴在第二届创新、技术合作及国际技术转让研讨会上通过。该研讨会于2015年9月21日在斯洛伐克布拉迪斯拉发举行。-->
      <!--</span>-->
      <!--&lt;!&ndash; 附件 &ndash;&gt;-->
      <!--<span>-->
        <!--{{ $t('m.aboutus.accessory_text') }}：-->
        <!--<a :href="download">-->
          <!--{{ $t('m.aboutus.can_download') }}-->
        <!--</a>-->
      <!--</span>-->
    <!--</div>-->
  </div>
</template>

<script>
//
import { manifestoFile } from '@/api/cceec/constant'
import CountryBox from '@/components/CountryBox'
export default {
  name: 'AboutUs',
  components: {
    CountryBox
  },
  data() {
    return {
      download: ''
    }
  },
  created() {
    this.getDownloadPath()
  },
  methods: {
    getDownloadPath() {
      const data = {
        languageType: this.$languageType
      }
      manifestoFile(data).then(res => {
        console.log(res)
        this.download = res.data.value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .aboutUs_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    /*width: 400px;*/
    margin: 70px auto 0;
    .text_box {
      padding-bottom: 7px;
      font-size: 30px;
      font-weight: 500;
    }
    .line_gary {
      height: 1px;
      width: 250px;
      background-color: #dcdcdc;
    }
    .line {
      margin-top: -2px;
      height: 2px;
      width: 100px;
      background-color: #409EFF;
    }
  }
  .aboutUs_content {
    width: 1200px;
    margin: 30px auto 0;
    /* 左侧文字介绍 */
    .aaboutUs_left {
      /*float: left;*/
      margin: 0 auto;
      padding-top: 10px;
      /*width: 555px;*/
      .contr_16 {
        display: block;
        padding: 0;
        margin: 0;
        /*width: 555px;*/
        height: auto;
        white-space: normal;
        font-size: 14px;
        text-align: justify;
        /*background-color: red;*/
      }
    }
    /* 右侧16国图片 */
    .aaboutUs_right {
      float: left;
      margin-left: 10px;
      padding-left: 15px;
      /*background-color: #006bbf;*/
    }
  }
  .line_35 {
    /*text-indent: 28px;*/
    line-height: 38px;
  }
  .line_en {
    line-height: 30px !important;
  }
  .jszyzx {
    margin: 40px auto 0;
    width: 1200px;
    line-height: 38px;
    .jszyzx_a {
      display: block;
      font-size: 14px;
      text-align: justify;
    }
    .jszyzx_b {
      display: block;
      font-size: 14px;
      font-weight: bold;
    }
    ol {
      margin-top: 0px;
      font-size: 14px;
      padding-left: 15px;
    }
  }
  .jszyzx_sm {
    margin: 20px auto 0;
    width: 1200px;
    height: 95px;
    font-size: 14px;
    line-height: 35px;
    span {
      display: block;
      a {
        color: #1478c1;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
</style>

import request from '@/utils/request'

//  行业
export function industryList(params) {
  return request({
    url: '/api/industryClassify',
    method: 'get',
    params: params
  })
}

//  行业第二级
export function secIndustryList() {
  return request({
    url: '/api/industryClassify/listSec',
    method: 'get'
  })
}

//  国家
export function countryList() {
  return request({
    url: '/api/cceec/country',
    method: 'get'
  })
}

//  宣言下载路径
export function manifestoFile(params) {
  return request({
    url: '/api/cceec/getXuanYanFile',
    method: 'get',
    params: params
  })
}

//  17国简介
export function getNationNews(params) {
  return request({
    url: '/api/cceec/getNationNews',
    method: 'get',
    params: params
  })
}

<template>
  <div class="main_box">
    <!--:style="{cursor: (item.id < 17 && $language==='cn')?'pointer':''}"-->
    <div
      v-for="item in countryOptions"
      :key="item.id"
      class="item_box"
      @click="handleReview(item)"
    >
      <div class="imgWrap">
        <img
          v-if="item.img"
          style="object-fit: contain;"
          :src="item.img"
        >
      </div>
      <div class="label">
        {{ $language === 'cn' ? item.name : item.english }}
      </div>
    </div>
  </div>
</template>

<script>
//
import img_1 from '@/assets/images/country_icon/1.png'
import img_2 from '@/assets/images/country_icon/2.png'
import img_3 from '@/assets/images/country_icon/2.png'
import img_4 from '@/assets/images/country_icon/4.png'
import img_5 from '@/assets/images/country_icon/5.png'
import img_6 from '@/assets/images/country_icon/6.png'
import img_7 from '@/assets/images/country_icon/7.png'
import img_8 from '@/assets/images/country_icon/8.png'
import img_9 from '@/assets/images/country_icon/9.png'
import img_10 from '@/assets/images/country_icon/10.png'
import img_11 from '@/assets/images/country_icon/11.png'
import img_12 from '@/assets/images/country_icon/12.png'
import img_13 from '@/assets/images/country_icon/13.png'
import img_14 from '@/assets/images/country_icon/14.png'
import img_15 from '@/assets/images/country_icon/15.png'
import img_16 from '@/assets/images/country_icon/16.png'
import img_17 from '@/assets/images/country_icon/17.png'

export default {
  data() {
    return {
      img_1,
      img_2,
      img_3,
      img_4,
      img_5,
      img_6,
      img_7,
      img_8,
      img_9,
      img_10,
      img_11,
      img_12,
      img_13,
      img_14,
      img_15,
      img_16,
      img_17,
      countryOptions: this.$constant.countryOptions
    }
  },
  created() {
    //
  },
  methods: {
    handleReview(item) {
      console.log(item)
      // if (this.$language === 'cn' && item.id < 17) {
      //   this.$router.push({ path: '/country', query: {id: item.id}})
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
  .main_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /*padding-top: 5px;*/
    /*width: 700px;*/
    width: 100%;
    height: 375px;
    /*border: 1px solid red;*/
    .item_box {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 101px;
      /*border: 1px solid blue;*/
      .label {
        margin-top: -8px;
        width: 116px;
        color: #333333;
        font-size: 14px;
        text-align: center;
        /*font-weight: bold;*/
        overflow: hidden;
        word-break:keep-all;
        white-space:nowrap;
        text-overflow:ellipsis;
      }
    }
  }
  .imgWrap {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    /*border: 1px solid deeppink;*/
  }
  img {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
</style>
